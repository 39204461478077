import { webClient } from "@api/web/WebClient";
import ReshopperButton from "@components/web/Button";
import { ReshopperCard, ReshopperCardContent } from "@components/web/Card";
import { withWebLayout } from "@components/web/Decorators";
import PaymentButton from "@components/web/financial/PaymentButton";
import PriceTag from "@components/web/financial/PriceTag";
import FooterWithPaymentIcons from "@components/web/FooterWithPaymentIcons";
import HeaderDefaultWithLogo from "@components/web/HeaderDefaultWithLogo";
import { FrontPageLayout } from "@components/web/layouts/FrontPageLayout";
import { useLocalization } from "@hooks/localization";
import { useQueryParameters } from "@hooks/navigation";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import { WebCreditPackageGetResponse, WebReepayPlansAvailableForSignupPlanGetResponse } from "@reshopper/web-client";
import { toDanishPriceFormat } from "@utils/formatting";
import { navigateTo404 } from "@utils/navigation";
import { round } from "lodash";
import { useEffect, useState } from "react";
import everyMonthTag from "../images/layout/everyMonthTag.svg";
import PlusLogo from "../images/layout/plus/plus-logo.svg";
import CreditCoin from "./CreditCoin.svg";
import CreditCoinPlusBanner from "./CreditCoinPlusBanner.svg";
import styles from "./index.module.scss";

type QueryParameters = {
	userId: string;
	planId: string;
	couponCode: string;
};

export default withWebLayout(function CreditsPage() {
	const queryParamenters = useQueryParameters<QueryParameters>();
	const [creditPackagesResponse, setCreditPackagesResponse] = useState<WebCreditPackageGetResponse[] | undefined>();
	const [plusFamilyPlan, setPlusFamilyPlan] = useState<WebReepayPlansAvailableForSignupPlanGetResponse | null | undefined>();

	useEffect(() => {
		const fetchCreditPackagesAsync = async () => {
			try {
				const creditPackagesResponse = await webClient().webCreditsPackagesGet();
				setCreditPackagesResponse(creditPackagesResponse.creditPackages);
			} catch (error) {
				navigateTo404();
			}
		};
		fetchCreditPackagesAsync();

		const fetchPlusFamilySubscriptionPlan = async () => {
			const plansAvailableForSignup = await webClient().webReepayPlansAvailableForSignupGet();

			const plusFamilyPlan = plansAvailableForSignup.plans.find(x => x.handle === "reepay_subscription_1");
			setPlusFamilyPlan(plusFamilyPlan ?? null);
		}

		fetchPlusFamilySubscriptionPlan();
	}, [queryParamenters]);

	const t = useLocalization();
	
	if (creditPackagesResponse === undefined) {
		return <>
			<HeaderDefaultWithLogo />
			<div style={{
				textAlign: "center",
				paddingTop: "20px"
			}}>
				<CircularProgress />
			</div>
		</>;
	}
	if (creditPackagesResponse === null) {
		navigateTo404();
	}
	if (creditPackagesResponse.length === 0) {
		return <h2>Credits not available</h2>
	}
	return <FrontPageLayout removeBlueBodyBackground>
		<HeaderDefaultWithLogo text={t("Credits_Content_InfoText")} />
		<div className={styles.contentContainer}>
			<div className={styles.packageContainer}>
				{creditPackagesResponse.map((p, index) => <Package package={p} key={index} />)}
			</div>
			<Box className={styles.extraContent}>
				<ReshopperCardContent className={styles.plusBanner}>
					<Typography variant="h4" className={styles.pbTitle}>
						<div className={styles.pbTitleText1}>{t("Credits_Banner_TitleText1")}</div>
						<div className={styles.coinContainer}>
							<img alt="Logo" src={CreditCoinPlusBanner} />
							<h3>
								<span>30</span><span>credits</span>
							</h3>
							<div className={styles.coinTag}>
								<img alt="Logo" src={everyMonthTag} />
							</div>
						</div>
						<Box className={styles.pbTextContainer}>
							<div className={styles.pbTitleText2} dangerouslySetInnerHTML={{ __html: t("Credits_Banner_TitleText2") }} />
							<div className={styles.plusLogoContainer}>
								<img alt="Logo" src={PlusLogo} />
							</div>
							<div>Family</div>
						</Box>
					</Typography>
					<p className={styles.pbText} dangerouslySetInnerHTML={{ __html: t("Credits_Banner_Text") }} />
					<Box textAlign="center">
						<ReshopperButton
							variant="contained"
							size="large"
							color="primary"
							style={{ width: "100%", maxWidth: "350px" }}
							href={"/plus"}
						>
							{plusFamilyPlan &&
								<>
									<PriceTag
										initialPrice={plusFamilyPlan.priceInHundreds / 100}
										variant="compact"
										currencySuffix={
											<>
												/
												{plusFamilyPlan.lengthInMonths === 12
													? t("Plus_Signup_Content_ShortYear")
													: t(
														"Plus_Signup_Content_ShortMonth"
													)}
											</>
										}
									/>
									<span style={{ display: "inline-block", padding: "0 3px" }}>-</span>
								</>
							} {t("Credits_Banner_Btn")}
						</ReshopperButton>
					</Box>
				</ReshopperCardContent>
			</Box>
		</div>
		<FooterWithPaymentIcons />
	</FrontPageLayout>
});
const onPurchase = () => {
	window.location.href = "/credits/purchase-success";
}

function Package(props: {
	package: WebCreditPackageGetResponse,
	key: number
}) {
	const discountedPriceInHundreds = props.package.discount.hasActiveDiscount ?
		(props.package.priceInHundreds / 100 * (100 - props.package.discount.percent)) :
		0;

	const pricePerCredit = !props.package.discount.hasActiveDiscount ?
		round(props.package.priceInHundreds / props.package.creditAmount / 100, 2) :
		round(discountedPriceInHundreds / props.package.creditAmount / 100, 2);

	return <ReshopperCard className={styles.package}>

		{props.package.ribbonText &&
			<div className={styles.ribbonText}>
				{props.package.ribbonText}
			</div>
		}

		<ReshopperCardContent className={styles.packageContent}>
			<div className={styles.coinContainer}>
				<img alt="Logo" src={CreditCoin} />
				<h3>
					<span>{props.package.creditAmount}</span><span>credits</span>
				</h3>
			</div>

			<h4>({toDanishPriceFormat(pricePerCredit)}/stk)</h4>

			<PaymentButton
				variant="primary"
				uniqueId={props.package.id}
				sessionType="credits"
				creditPackageId={props.package.id}
				onPurchase={onPurchase}
			>
				<PriceTag
					variant="compact"
					initialPrice={props.package.priceInHundreds / 100}
					discountedPrice={discountedPriceInHundreds / 100}
				/>
			</PaymentButton>
		</ReshopperCardContent>
	</ReshopperCard>;
}